import React, { useEffect } from 'react';
import { createSearchParams, useNavigate } from "react-router-dom";
import { Grid, Tab } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import VerticalTabDetailPanel from '@lp/lp-ui/src/components/panels/VerticalTabPanel';
import UserBasicInfoPanel from './UserBasicInfoPanel';
import UserAddressPanel from './UserAddressPanel';
import UserContactsPanel from './UserContactsPanel';
import { LoadingBackDrop, SnackDisplay, MessageDialog } from '@lp/lp-ui';
import UserSecurityPanel from './UserSecurityPanel';
import { getCurrentUserApplications, getUser, messagesDisplayed, snackDisplayed } from '../../app/userSlice';
import UserApplicationsPanel from './UserApplicationsPanel';

export interface UserPanelProps {
    handleUnauthorized: () => void
}

export default function Profile() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { messages, loading, snack } = useAppSelector(store => store.user);
    const loginState = useAppSelector(store => store.login);

    useEffect(() => {
        const initProfile = async () => {
            const dispatchResult = await dispatch(getUser());
            if (dispatchResult.meta.requestStatus !== "fulfilled") {
                navigate({
                    pathname: '/',
                    search: `?${createSearchParams({ returnUrl: window.location.origin + '/account' })}`
                });
            }
            else {
                await dispatch(getCurrentUserApplications());
            }
        };
        initProfile().catch(console.error);
    }, []);

    const handleUnauthorized = () => {
        navigate({
            pathname: '/',
            search: `?${createSearchParams({ returnUrl: window.location.origin + '/account' })}`
        });
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <VerticalTabDetailPanel tabs={[
                        {
                            tabComponent: <Tab label="General Information" key="vtp-gi" />,
                            panelComponent: <UserBasicInfoPanel handleUnauthorized={handleUnauthorized} />
                        },
                        {
                            tabComponent: <Tab label="Security" key="vtp-apps" />,
                            panelComponent: <UserSecurityPanel handleUnauthorized={handleUnauthorized} />
                        },
                        {
                            tabComponent: <Tab label="Address" key="vtp-addr" />,
                            panelComponent: <UserAddressPanel handleUnauthorized={handleUnauthorized} />
                        },
                        {
                            tabComponent: <Tab label="Contacts" key="vtp-c" />,
                            panelComponent: <UserContactsPanel handleUnauthorized={handleUnauthorized} />
                        },
                        {
                            tabComponent: <Tab label="Applications" key="vtp-s" />,
                            panelComponent: <UserApplicationsPanel />
                        }
                    ]} />
                </Grid>
            </Grid>

            <MessageDialog
                title="Attention"
                messages={messages}
                handleClose={() => { dispatch(messagesDisplayed([])); }}
            />

            <SnackDisplay
                snack={snack}
                handleClose={() => { dispatch(snackDisplayed(null)); }}
            />

            <LoadingBackDrop open={loading || loginState.loading} />
        </React.Fragment>
    );
}
