import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import {
    Button, Typography, Box, Stack
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { loginInit, spaReset } from "../../app/loginSlice"
import { parseReturnUrl } from '../../functions';
import { LoginStage } from '../../interfaces';

export default function PasswordResetDone() {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const loginState = useAppSelector(store => store.login);

    useEffect(() => {
        if (loginState?.loginStage == LoginStage.Initialization) {
            dispatch(loginInit(parseReturnUrl(searchParams.get("returnUrl"))));
        }
    }, []);

    const handleReturnToLogin = () => {
        dispatch(spaReset());
        navigate('/');
    };

    return (
        <React.Fragment>
            <Box width={1}>
                <Stack spacing={1.5}>
                    <Box>
                        <CheckCircleIcon fontSize='large' color='success' />
                    </Box>
                    <Typography variant="h4" color="primary">Reset your account password</Typography>
                    <Typography variant="body1">Your password has been reset successfully</Typography>
                    <Button id="passwordreset-done-return-to-login-button" onClick={handleReturnToLogin} fullWidth variant="contained">
                        Return to Login
                    </Button>
                </Stack>
            </Box>
        </React.Fragment>
    );
}
