import React from 'react';
import { Box, Typography, Link, Stack } from '@mui/material';

export default function Copyright(props: any) {
  return (
    <React.Fragment>
      <Box {...props} textAlign="center" id="footer-container">
        <Stack spacing={0.5}>
          <Typography variant="body1" fontWeight="bold" id="footer-links-container">
            <Link id="footer-legal-link" href="https://www.logisticsplus.com/about-us/company-profile/terms-conditions/website-terms-of-use/"
                  target="_blank" m={1} color="text.secondary">LEGAL</Link>
            <Link id="footer-privacy-link" href="https://www.logisticsplus.com/about-us/company-profile/terms-conditions/privacy-policy/"
                  target="_blank" sx={{margin: 1, color: 'text.secondary' }}>PRIVACY</Link>
          </Typography>
          <Typography variant="body1" color="text.secondary" id="footer-copyright-container">
            {'© '}{new Date().getFullYear()}{' '}
            <Link id="footer-logisticsplus-link" color="inherit" href="https://www.logisticsplus.com" target="_blank">Logistics Plus Inc.</Link>
            {' All rights reserved.'}
          </Typography>
        </Stack>
      </Box>
    </React.Fragment>
  );
}
