import React, { useEffect } from 'react';
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import { Button, Typography, Box, Stack } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { loginInit, spaReset } from "../../app/loginSlice"
import { parseReturnUrl } from '../../functions';
import { LoginStage } from '../../interfaces';

export default function PasswordLinkSent() {
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const loginState = useAppSelector(store => store.login);

    useEffect(() => {
        if (loginState?.loginStage == LoginStage.Initialization) {
            dispatch(loginInit(parseReturnUrl(searchParams.get("returnUrl"))));
        }
    }, []);

    const handleReturnToLogin = () => {
        dispatch(spaReset());
        navigate({
            pathname: '/',
            search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
        });
    };

    return (
        <React.Fragment>
            <Box width={1}>
                <Stack spacing={1.5}>
                    <Box>
                        <CheckCircleIcon fontSize='large' color='success' />
                    </Box>
                    <Typography variant="h4" color="primary">Password reset link sent</Typography>
                    <Typography variant="body1">
                        Password reset link was sent to user email on file. If you did not receive the email, contact your administrator.
                    </Typography>
                    <Button id="passwordreset-linksent-return-to-login-button" onClick={handleReturnToLogin} fullWidth variant="contained">
                        Return To Login
                    </Button>
                </Stack>
            </Box>
        </React.Fragment>
    );
}
