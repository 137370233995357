import React, { useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import { Typography, Link, Box, Stack } from '@mui/material';
import { useAppSelector } from "../../app/hooks"
import { parseReturnUrl } from '../../functions';
import { ApplicationModel } from '../../interfaces';

export default function Redirect() {
    const loginState = useAppSelector(store => store.login);
    const [searchParams] = useSearchParams();
    const returnUrl: string = parseReturnUrl(searchParams.get("returnUrl"));
    const client: ApplicationModel | null = loginState?.userApplication;

    useEffect(() => {
        window.location.href = returnUrl;
    }, []);

    return (
        <React.Fragment>
            <Box width={1}>
                <Stack spacing={2}>
                    <Typography variant="h4" color="primary">You are logged in</Typography>
                    <Typography variant="body1">Redirecting to {client?.clientUri ?? client?.clientName ?? returnUrl}</Typography>
                    <Typography variant="body1">
                        <Link id="redirect-application-link" href={client?.clientUri ?? returnUrl}>Not redirected? Click Here</Link>
                    </Typography>
                </Stack>
            </Box>
        </React.Fragment>
    );
}
