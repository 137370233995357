import React from 'react';
import { Check, Clear } from '@mui/icons-material';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

export interface PasswordValidationResult {
    lengthGt8: boolean,
    upperCaseChar: boolean,
    lowerCaseChar: boolean,
    digit: boolean,
    specialChar: boolean,
    matchConfirmPassword: boolean
}

export function ValidatePassword(pwd: string, pwdConfirm: string) : PasswordValidationResult {
    const result : PasswordValidationResult = {
        lengthGt8: pwd.length >= 8,
        upperCaseChar: /[A-Z]/.test(pwd),
        lowerCaseChar: /[a-z]/.test(pwd),
        digit: /[0-9]/.test(pwd),
        specialChar: /[~@#$%^&()[\]<>{}+\-*\/\\=_|!?.,:;]/.test(pwd),
        matchConfirmPassword: pwd.length > 0 && pwd == pwdConfirm
    };
    return result;
}

export function IsPasswordValid(pwd: string, pwdConfirm: string) : boolean {
    const validationResult = ValidatePassword(pwd, pwdConfirm);
    const result =  validationResult.lengthGt8 &&
                    validationResult.upperCaseChar &&
                    validationResult.lowerCaseChar &&
                    validationResult.digit &&
                    validationResult.specialChar &&
                    validationResult.matchConfirmPassword;
    return result;
}

type ValidationProps = {
    valid: PasswordValidationResult
}

export default function PasswordRequirements({ valid }: ValidationProps) {
    return (
        <React.Fragment>
            <List dense={true}>
                <ListItem disablePadding={true}>
                    <ListItemIcon sx={{ minWidth: 20 }}>
                        {valid.lengthGt8 ? <Check sx={{ fontSize: 11 }} color="success" /> : <Clear sx={{ fontSize: 11 }} color="error" />}
                    </ListItemIcon>
                    <ListItemText primary="Contains at least 8 characters" />
                </ListItem>
                <ListItem disablePadding={true}>
                <ListItemIcon sx={{ minWidth: 20 }}>
                        {valid.upperCaseChar ? <Check sx={{ fontSize: 11 }} color="success" /> : <Clear sx={{ fontSize: 11 }} color="error" />}
                    </ListItemIcon>
                    <ListItemText primary="Contains at least one uppercase letter" />
                </ListItem>
                <ListItem disablePadding={true}>
                    <ListItemIcon sx={{ minWidth: 20 }}>
                        {valid.lowerCaseChar ? <Check sx={{ fontSize: 11 }} color="success" /> : <Clear sx={{ fontSize: 11 }} color="error" />}
                    </ListItemIcon>
                    <ListItemText primary="Contains at least one lowercase letter" />
                </ListItem>
                <ListItem disablePadding={true}>
                    <ListItemIcon sx={{ minWidth: 20 }}>
                        {valid.digit ? <Check sx={{ fontSize: 11 }} color="success" /> : <Clear sx={{ fontSize: 11 }} color="error" />}
                    </ListItemIcon>
                    <ListItemText primary="Contains at least one digit" />
                </ListItem>
                <ListItem disablePadding={true}>
                    <ListItemIcon sx={{ minWidth: 20 }}>
                        {valid.specialChar ? <Check sx={{ fontSize: 11 }} color="success" /> : <Clear sx={{ fontSize: 11 }} color="error" />}
                    </ListItemIcon>
                    <ListItemText primary="Contains at least one special character" />
                </ListItem>
                <ListItem disablePadding={true}>
                    <ListItemIcon sx={{ minWidth: 20 }}>
                        {valid.matchConfirmPassword ? <Check sx={{ fontSize: 11 }} color="success" /> : <Clear sx={{ fontSize: 11 }} color="error" />}
                    </ListItemIcon>
                    <ListItemText primary="Matches with the confirm password" />
                </ListItem>
            </List>
        </React.Fragment>
    );
}
