import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { useAppSelector } from "../app/hooks"
import { ApplicationModel, LoginStage } from '../interfaces';

export default function Logo(props: any) {
    const loginState = useAppSelector(store => store.login);
    const client: ApplicationModel | null = loginState?.userApplication;

    return (
        loginState?.loginStage == LoginStage.Initialization
            ? <React.Fragment></React.Fragment>
            :
            <React.Fragment>
                <Box {...props} id="logo-container">
                    <Box component='img' id="logo-img" src={client?.logoUri ?? '/lp-logo.png'} mb={1} maxWidth={220} />
                    <Typography variant='subtitle2' color="text.secondary" id="logo-text">
                        Logistics Plus® {client?.description ?? 'Universal Customer Portal'}
                    </Typography>
                </Box>
            </React.Fragment>
    );
}
