import React, { useState } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { Box, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { logoutUser } from '../app/loginSlice';

export default function ProfileHeader(props: any) {
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { data, applications } = useAppSelector(store => store.user);

    const handleSignOut = async () => {
        menuClose();
        const dispatchResult = await dispatch(logoutUser());
        if (dispatchResult.meta.requestStatus == "fulfilled") {
            navigate({
                pathname: '/',
                search: `?${createSearchParams({ returnUrl: window.location.origin + '/account' })}`
            });
        }
    };

    const menuClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    }

    return (
        <React.Fragment>
            <Box {...props} display='flex' flexDirection='row' justifyContent='space-between' alignItems='center' bgcolor="#0078d7">
                <Box display='flex' flexDirection='row' alignItems='center'>
                    <Box component='img' src={`${window.location.origin}/lp-account-logo.png`} m={1} maxWidth={50} />
                    <Box component="span" color="white" fontSize={24}>LogisticsPlus Account</Box>
                </Box>
                <Box>
                    <Box component="span" color="white">
                        {data && `${data.firstName} ${data.lastName}`}
                    </Box>
                    <IconButton
                        size="large"
                        sx={{ color: "white" }}
                        onClick={(event) => {
                            setAnchorEl(event.currentTarget)
                            setMenuOpen(true);
                        }}
                    >
                        <AccountCircleIcon fontSize='large' />
                    </IconButton>
                </Box>
            </Box>

            <Menu
                open={menuOpen}
                anchorEl={anchorEl}
                onClose={menuClose}
            >
                {applications && applications.length > 0 &&
                    <Box>
                        <ListItemText sx={{ ml: 2 }}>
                            <Typography variant='h6'>Applications</Typography>
                        </ListItemText>
                        {applications.map((application, i) => (
                            <MenuItem onClick={() => { menuClose(); window.open(`${application.clientUri}`, '_blank'); }} key={i}>
                                <ListItemIcon>
                                    <OpenInNewIcon />
                                </ListItemIcon>
                                <ListItemText>
                                    {application.clientName}
                                </ListItemText>
                            </MenuItem>
                        ))}
                        <Divider />
                    </Box>
                }
                <MenuItem onClick={handleSignOut}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText>Log Out</ListItemText>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}
