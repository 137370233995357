import React from 'react';
import {
    BrowserRouter as Router, Routes, Route, Outlet
} from "react-router-dom";
import { Paper, Box, Container } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { lightTheme, darkTheme } from '@lp/lp-ui'
import Copyright from './components/copyright';
import Logo from './components/logo';
import ProfileHeader from './components/profileHeader';
import User from './components/User';
import SignIn from './components/SignIn';
import MfaProvides from './components/Mfa/Providers';
import Mfa from './components/Mfa';
import ForgotPassword from './components/PasswordReset/ForgotPassword';
import PasswordLinkSent from './components/PasswordReset/LinkSent';
import PasswordReset from './components/PasswordReset';
import PasswordResetDone from './components/PasswordReset/Done';
import PasswordChange from './components/PasswordChange';
import FillUserProfile from './components/FillUserProfile';
import Client from './components/Client';
import Redirect from './components/Client/Redirect';
import Profile from './components/Profile';
import './index.css';

const Main: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route element={<LoginLayout />}>
                    <Route path="/" element={<User />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/mfa" element={<Mfa />} />
                    <Route path="/mfa/providers" element={<MfaProvides />} />
                    <Route path="/password/forgot" element={<ForgotPassword />} />
                    <Route path="/password/link/sent" element={<PasswordLinkSent />} />
                    <Route path="/password/reset" element={<PasswordReset />} />
                    <Route path="/password/reset/done" element={<PasswordResetDone />} />
                    <Route path="/password/change" element={<PasswordChange />} />
                    <Route path="/signin/profile" element={<FillUserProfile />} />
                    <Route path="/client" element={<Client />} />
                    <Route path="/client/redirect" element={<Redirect />} />
                </Route>
                <Route element={<ProfileLayout />}>
                    <Route path="/account" element={<Profile />} />
                </Route>
            </Routes>
        </Router>
    )
}

const theme = createTheme({...lightTheme,
    typography: {
        ...lightTheme.typography,
        button: {
            textTransform: "none"
        },
        body1: {
            fontSize: 13
        },
        subtitle1: {
            fontSize: 13
        }
    },
    components: {
        ...lightTheme.components,
        MuiTextField: {
            ...lightTheme.components!.MuiTextField,
            defaultProps: {
                variant: "outlined"
            }
        },
        MuiLink: {
            defaultProps: {
                underline: "none"
            }
        },
        MuiButton: {
            defaultProps: {
                size: "large"
            }
        },
        MuiInputLabel: {},
        MuiFormLabel: {},
        MuiFormHelperText: {}
    }
});

const LoginLayout = () => (
    <ThemeProvider theme={theme}>
        <Container component="main" maxWidth={false} sx={{
            textAlign: 'center',
            maxWidth: '400px',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}></Box>
            <Box sx={{ marginTop: { xs: 3, md: 0} }}>
                <Logo sx={{ mb: { xs: 0, md: 1.5 } }} />
                <Paper sx={{
                        backgroundColor: { xs: '#fafafa', md: '#ffffff' },
                        boxShadow: { xs: "none", md: 1 }
                    }}
                >
                    <Box
                        sx={{
                            pl: 3, pr: 3, pt: 2, pb: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                        <Outlet />
                    </Box>
                </Paper>
            </Box>
            <Copyright sx={{ mb: 2 }} />
        </Container>
    </ThemeProvider>
);

const ProfileLayout = () => (
    <ThemeProvider theme={lightTheme}>
        <Container component="div" maxWidth="lg" sx={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }}>
            <Box>
                <ProfileHeader sx={{ mb: 1.3, mt: 0.2 }} />
                <Box>
                    <Outlet />
                </Box>
            </Box>
            <Copyright sx={{ m: 2 }} />
        </Container>
    </ThemeProvider>
);

export default Main;
