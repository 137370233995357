import { ApiViewResponseError, ApiViewResponse } from '../interfaces';

class APIService {
    async fetch(url: RequestInfo, opts: RequestInit): Promise<Response> {
        return new Promise<Response>(async (resolve, reject) => {
            const res = await fetch(url, {
                ...opts,
                headers: {
                    'Content-Type': "application/json"
                }
            });
            if (res.ok) {
                resolve(res);
            }
            else {
                reject(res);
            }
        })
    }
}

const service = new APIService();
export default service;

export const getBodyErrors = async (err: any): Promise<ApiViewResponseError[]> => {
    if (err.body) {
        const apiResponseBody = await err.json() as ApiViewResponse;
        return (apiResponseBody.errors);
    }
    return [{ detail: err.message ?? err.toString(), title: 'Error', code: '', id: '', status: '' }];
}
