import { isValidPhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';
import { ContactType } from './interfacesProfile';

export const UserPersonalDetailsSchema = Yup.object().shape({
    firstName: Yup.string().trim()
        .required("First Name is required")
        .max(64, "First Name max length is 64 characters"),
    lastName: Yup.string().trim()
        .required("Last Name is required")
        .max(64, "Last Name max length is 64 characters"),
    phoneNumber: Yup.string().trim()
        .test('phone-number', 'Phone number is invalid', (value) => !value ? true : isValidPhoneNumber(value))
});

export const UserNameSchema = Yup.object().shape({
    userName: Yup.string().trim()
        .required("User Name is required")
        .max(256, "User Name max length is 256 characters")
});


export const UserContactSchema = Yup.object().shape({
    contactType: Yup.number()
        .min(1, "Contact Type is required"),
    contact: Yup.string().trim()
        .required("Contact is required")
        .max(128, "Contact max length is 128 characters"),
    phoneExtension: Yup.string().trim()
        .when('contactType', {
            is: ContactType.Phone,
            then: (schema) => schema.matches(/^\d*$/, "Digits only"),
            otherwise: (schema) => schema,
        })
});

export const AddressSchema = Yup.object().shape({
    street1: Yup.string().trim()
        .required("Street 1 is required")
        .max(128, "Street 1 max length is 128 characters"),
    street2: Yup.string().trim()
        .max(64, "Street 2 max length is 64 characters"),
    city: Yup.string().trim()
        .required("City is required")
        .max(128, "City max length is 128 characters"),
    state: Yup.string().trim()
        .required("State is required")
        .max(128, "State max length is 128 characters"),
    postalCode: Yup.string().trim()
        .required("Postal Code is required")
        .max(16, "Code max length is 16 characters"),
    countryAlpha3Code: Yup.string().trim()
        .required("Country is required")
});
