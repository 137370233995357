import config from "./config.json";

export enum HTTP_METHOD {
    GET = "get",
    POST = "post",
    PUT = "put",
    PATCH = "patch",
    DELETE = "delete"
}

export const ADMIN_APP = 'admin-app';

export const IDENTITY_SERVER_URL: string = config.IDENTITY_SERVER_URL;

export const GA_MEASUREMENT_ID: string = config.GA_MEASUREMENT_ID;

export const RECAPTCHA_SITEKEY: string = config.RECAPTCHA_SITEKEY;
