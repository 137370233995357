import React from 'react';
import { Box, Alert, Typography } from '@mui/material';
import { Message } from '../interfaces';

type ErrosProps = {
    errors: Message[]
}

const errorsBox: React.FC<ErrosProps> = ({ errors }: ErrosProps) => {
    return errors.length > 0 ? (
        <React.Fragment>
            <Box textAlign={'left'} id="errors-container">
                {errors.map((d, i) => (
                    <Alert severity="error" sx={{ pl: 1, pt: 0, pb: 0 }} key={i}>
                        <Typography>{d.details}</Typography>
                    </Alert>
                ))}
            </Box>
        </React.Fragment>
    ) : (
        <React.Fragment></React.Fragment>
    )
};

export default errorsBox
