import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import {
    Button, TextField, Typography, Box, Backdrop, Stack, CircularProgress, Link
} from '@mui/material';
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import {
    LoginStage, ApiViewEntityResponse, ApiViewResponseError,
    LoginStageResultModel, LoginResultModel, LoginSigninProfileModel
} from "../../interfaces"
import { logoutUser, spaSessionExpired, updateUserProfile } from "../../app/loginSlice"
import ErrorsBox from '../errorsBox';
import { parseReturnUrl } from '../../functions';

export default function FillUserProfile() {
    const [searchParams] = useSearchParams();
    const [emailAddress, setEmailAddress] = useState('');
    const [emailError, setEmailError] = useState('');
    const loginState = useAppSelector(store => store.login);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (loginState?.userData == null) {
            window.location.href = '/' + window.location.search;
        }
    }, []);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (emailAddress.length == 0) {
            setEmailError('Email address could not be empty.');
            return;
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress)) {
            setEmailError('Email address is invalid.');
            return;
        }
        else {
            setEmailError('');
        }

        const body: LoginSigninProfileModel = {
            email: emailAddress,
            returnUrl: parseReturnUrl(searchParams.get("returnUrl"))
        };
        const dispatchResult = await dispatch(updateUserProfile(body));
        if (dispatchResult.meta.requestStatus == "fulfilled") {
            const loginData = dispatchResult.payload as ApiViewEntityResponse<LoginStageResultModel<LoginResultModel>>;
            if (loginData.data.stage == LoginStage.Authenticated) {
                navigate({
                    pathname: '/client',
                    search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
                });
            }
        }
        else if (dispatchResult.meta.requestStatus == "rejected") {
            const errors = dispatchResult.payload as ApiViewResponseError[];
            if (errors?.some(e => e.code == "UNAUTHORIZED")) {
                dispatch(spaSessionExpired());
                navigate({
                    pathname: '/',
                    search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
                });
            }
        }
    };

    const handleCancel = async () => {
        const dispatchResult = await dispatch(logoutUser());
        if (dispatchResult.meta.requestStatus == "fulfilled") {
            navigate({
                pathname: '/',
                search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
            });
        }
    };

    return (
        <React.Fragment>
            <Box component="form" onSubmit={handleSubmit} width={1} noValidate>
                <Stack spacing={1.5}>
                    <Typography variant="h4" color="primary">Enter your email</Typography>
                    <Typography variant="body1">
                        You don't have an email address associated with your account. Please enter your correct email.
                    </Typography>
                    <Box>
                        <ErrorsBox errors={loginState.messages} />
                        <TextField
                            inputProps={{ style: { height: 12 } }}
                            margin="normal" id="emailAddress" name="emailAddress" type="text"
                            label="Enter email address" autoComplete="current-password"
                            onChange={e => { setEmailAddress(e.target.value) }}
                            error={emailError.length > 0}
                            helperText={emailError}
                            required fullWidth autoFocus />
                    </Box>
                    <Button
                        id="filluserprofile-continue-button"
                        type="submit" fullWidth variant="contained"
                    >
                        Continue
                    </Button>
                    <Typography variant="body1" pt={1}>
                        <Link id="filluserprofile-cancel-link" href="#" onClick={handleCancel}>Cancel</Link>
                    </Typography>
                </Stack>
            </Box>
            <Backdrop open={loginState.loading} className="loading">
                <CircularProgress />
            </Backdrop>
        </React.Fragment>
    );
}
