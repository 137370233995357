import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'
import loginReducer from './loginSlice'
import userReducer from './userSlice'
import cacheReducer from './cacheSlice';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  // dont store orders or orgs in root, we will whitelist specific entries in its own config
  blacklist: ['']
};

const rootReducer = combineReducers({
  user: userReducer,
  genericCache: cacheReducer,
  login: loginReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
