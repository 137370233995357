export enum ContactType {
    Undefined = 0,
    Phone = 1,
    Mobile = 2,
    Fax = 3,
    Email = 4
}

export const ContactTypeSelectItems: {
    value: string | number,
    label: string
}[] = [
        { value: 0, label: "-" },
        { value: 1, label: "Phone" },
        { value: 2, label: "Mobile" },
        { value: 3, label: "Fax" }
    ];

export const ContactTypeLabels: {
    value: string | number,
    label: string
}[] = [
        { value: 1, label: "Phone" },
        { value: 2, label: "Mobile" },
        { value: 3, label: "Fax" },
        { value: 4, label: "Email" }
    ];

export enum AddressType {
    Default = 1
}

export enum FieldDefinitionType {
    "string",
    "Date",
    "DateTime",
    "number",
    "enum",
    "boolean",
    "GUID",
    "Country",
    "User"
}

export interface UserClientModel {
    clientId: number
}

export interface UserContactDataModel {
    contact: string,
    contactType: ContactType,
    phoneExtension: string
}

export interface UserContactModel extends UserContactDataModel {
    id: number
}

export interface AddressDataModel {
    street1: string,
    street2: string,
    city: string,
    state: string,
    postalCode: string,
    countryAlpha3Code: string
}

export interface AddressModel extends AddressDataModel {
    id: number
}

export interface UserAddressModel {
    id: number,
    addressType: AddressType,
    address: AddressModel
}

export interface UserListItemModel {
    id: number,
    userName: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string,
    enabled: boolean
}

export interface UserPersonalDetailsModel {
    firstName: string,
    lastName: string,
    phoneNumber: string
}

export interface UserProfileDataModel extends UserPersonalDetailsModel {
    userName: string,
    email: string,
    enabled: boolean,
    forcePasswordReset: boolean
}

export interface UserDataModel extends UserProfileDataModel {
    contacts: UserContactModel[],
    userAddresses: UserAddressModel[],
    userClients: UserClientModel[]
}

export interface UserModel extends UserProfileDataModel {
    id: number,
    externalId: string,
    emailConfirmed: boolean,
    phoneNumberConfirmed: boolean,
    twoFactorEnabled: boolean,
    lockoutEnabled: boolean,
    accessFailedCount: number,
    contacts: UserContactModel[],
    userAddresses: UserAddressModel[],
    userClients: UserClientModel[]
}

export interface CountryModel {
    countryAlpha3Code: string,
    name: string
}

export interface ApplicationModel {
    id: number,
    clientId: string,
    clientName: string,
    description: string,
    clientUri: string,
    logoUri: string
}
