import React from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { UserNameSchema } from '../../validationSchemes';
import { EntityEditDialog, TextInput } from '@lp/lp-ui';
import { updateUserName } from '../../app/userSlice'
import { useAppDispatch } from '../../app/hooks';
import { ApiViewResponseError } from '../../interfaces';

interface UserNameModel {
    userName: string
}

type UserNameEditDialogProps = {
    open: boolean,
    userName: string,
    handleUnauthorized: () => void,
    handleClose: () => void
}

const UserNameEditDialog: React.FC<UserNameEditDialogProps> = (props: UserNameEditDialogProps) => {
    const dispatch = useAppDispatch();

    const formik = useFormik<UserNameModel>({
        initialValues: { userName: props.userName },
        enableReinitialize: true,
        validationSchema: UserNameSchema,
        onSubmit: async (values) => {
            const result = await dispatch(updateUserName(values.userName));
            const success = result.meta.requestStatus === 'fulfilled';
            if (success) {
                handlePopupClose();
            }
            else {
                const errors = result.payload as ApiViewResponseError[];
                if (errors.some(e => e.status === '401')) {
                    props.handleUnauthorized();
                }
            }
            return success;
        }
    });

    const {
        errors,
        touched,
        getFieldProps,
        submitForm
    } = formik;

    const handlePopupClose = () => {
        formik.resetForm();
        props.handleClose();
    }

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={submitForm}>
                    <EntityEditDialog
                        open={props.open}
                        editing={true}
                        maxWidth="sm"
                        fullWidth
                        title="User Name"
                        handleClose={handlePopupClose}
                        handleSave={submitForm}
                        showMoreMenu={false}
                    >
                        <TextInput
                            isEditing={true}
                            required
                            type="string"
                            label="User Name"
                            {...getFieldProps("userName")}
                            error={Boolean(touched.userName && errors.userName)}
                            helperText={touched.userName && errors.userName}
                        />
                    </EntityEditDialog>
                </Form>
            </FormikProvider>
        </React.Fragment>
    );
}

export default UserNameEditDialog;
