import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import { CacheLookup, CachedEntity, fetchCountries, fetchApplications } from './cacheSlice';

const useCache: (entity: CachedEntity) => CacheLookup[] = (entity: CachedEntity) => {
    const cache = useAppSelector(s => s.genericCache.cache[entity]);
    const pending = useAppSelector(s => s.genericCache.pending[entity]);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (cache.length === 0 && !pending) {
            dispatch(fetchCountries());
            dispatch(fetchApplications());
        }
    });

    return cache;
}

export default useCache;
