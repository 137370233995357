import React from 'react';
import { Box, Typography, Link } from '@mui/material';

type MicrosoftLoginProps = {
  onLoginClick: () => void
}

export default function MicrosoftLoginButton({ onLoginClick }: MicrosoftLoginProps) {
  return (
    <React.Fragment>
      <Box pb={1}>
        <Link id="signin-with-microsoft-link" href="#" onClick={onLoginClick} border="1px solid #8C8C8C" width={1}
          sx={{ backgroundColor: "#fff", textDecoration: "none", pt: "0.3rem", pb: "0.4rem", pl: "0.4rem", pr: "0.4rem" }}>
          <Box component='img' src='/ms-icon.png' mb="-0.3rem" />
          <Typography component="span" sx={{ color: "#5E5E5E", ml: "0.6rem", fontSize: "0.9rem" }}>
            Sign in with Microsoft
          </Typography>
        </Link>
      </Box>
    </React.Fragment>
  );
}