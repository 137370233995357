import React, { ChangeEvent, useState } from 'react';
import { useTheme, Grid, Typography, Button, IconButton, Box } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { updateUserPersonalDetails } from '../../app/userSlice';
import { UserProfileDataModel } from '../../interfacesProfile';
import { UserPersonalDetailsSchema } from '../../validationSchemes';
import { TextInput, EditIcon, CancelIcon, SaveIcon, PhoneNumberInput } from '@lp/lp-ui';
import { NewUserProfileDataModel } from '../../functions';
import UserNameEditDialog from './UserNameEditDialog';
import { ApiViewResponseError } from '../../interfaces';
import { UserPanelProps } from '.';

const UserBasicInfoPanel: React.FC<UserPanelProps> = ({ handleUnauthorized }: UserPanelProps) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const userState = useAppSelector(store => store.user);
    const [isEditing, setIsEditing] = useState(false);
    const [userNameEditDialogOpen, setUserNameEditDialogOpen] = useState(false);

    const formik = useFormik<UserProfileDataModel>({
        initialValues: userState.data ?? NewUserProfileDataModel(),
        enableReinitialize: true,
        validationSchema: UserPersonalDetailsSchema,
        onSubmit: async (values) => {
            if (!formik.dirty) {
                setIsEditing(false);
                return true;
            }
            const result = await dispatch(updateUserPersonalDetails(values));
            const success = result.meta.requestStatus === 'fulfilled';
            if (success) {
                setIsEditing(false);
            }
            else {
                const errors = result.payload as ApiViewResponseError[];
                if (errors.some(e => e.status === '401')) {
                    handleUnauthorized();
                }
            }
            return success;
        }
    });

    const {
        errors,
        touched,
        getFieldProps,
        submitForm
    } = formik;

    const cancelEditing = () => {
        formik.resetForm();
        setIsEditing(false);
    }

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={submitForm} >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h5" color="primary">
                                Access Details
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0} columnSpacing={6}>
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="space-between">
                                        <TextInput
                                            isEditing={false}
                                            type="string"
                                            label="User Name"
                                            required
                                            {...getFieldProps("userName")}
                                            error={Boolean(touched.userName && errors.userName)}
                                            helperText={touched.userName && errors.userName} />
                                        <Box mr={-2}>
                                            <Button variant="text" onClick={() => { setUserNameEditDialogOpen(true); }}>Edit</Button>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextInput
                                        isEditing={false}
                                        type="string"
                                        label="Email"
                                        {...getFieldProps("email")}
                                        required
                                        error={Boolean(touched.email && errors.email)}
                                        helperText={touched.email && errors.email} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="h5" color="primary">
                                    Personal Details
                                </Typography>
                                <Box mr={-1}>
                                    {isEditing
                                        ?
                                        <React.Fragment>
                                            <IconButton id="cancel-icon-btn" onClick={cancelEditing} size="small">
                                                <CancelIcon />
                                            </IconButton>
                                            <IconButton onClick={submitForm} size="small">
                                                <SaveIcon />
                                            </IconButton>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <IconButton
                                                size="small" id="edit-icon-btn" color='inherit'
                                                onClick={() => { setIsEditing(true); }}
                                                sx={{ color: theme.palette.grey[600] }}
                                                disableRipple
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </React.Fragment>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={0} columnSpacing={6}>
                                <Grid item xs={6}>
                                    <TextInput
                                        isEditing={isEditing}
                                        type="string"
                                        label="First Name"
                                        required
                                        {...getFieldProps("firstName")}
                                        error={Boolean(touched.firstName && errors.firstName)}
                                        helperText={touched.firstName && errors.firstName} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput
                                        isEditing={isEditing}
                                        type="string"
                                        label="Last Name"
                                        required
                                        {...getFieldProps("lastName")}
                                        error={Boolean(touched.lastName && errors.lastName)}
                                        helperText={touched.lastName && errors.lastName}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <PhoneNumberInput
                                        isEditing={isEditing}
                                        defaultCountry="US"
                                        label="Phone Number"
                                        {...getFieldProps("phoneNumber")}
                                        onChange={(e: string | ChangeEvent<any>) => { formik.setFieldValue("phoneNumber", e); } }
                                        error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                                        helperText={touched.phoneNumber && errors.phoneNumber}
                                    />
                                </Grid>
                                <Grid item xs={6}></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>

            <UserNameEditDialog
                open={userNameEditDialogOpen}
                userName={userState.data?.userName ?? ""}
                handleUnauthorized={handleUnauthorized}
                handleClose={() => { setUserNameEditDialogOpen(false); }}
            />

        </React.Fragment>
    );
}

export default UserBasicInfoPanel;
