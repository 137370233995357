import { CacheLookup } from "./app/cacheSlice";
import {
    AddressModel, AddressType, ApplicationModel, ContactType, CountryModel,
    UserAddressModel, UserContactModel, UserDataModel, UserModel, UserProfileDataModel
} from "./interfacesProfile";

export function parseReturnUrl(returnUrl: string | null) : string {
    const result = returnUrl == null ? "" : returnUrl;
    return result;
}

export const NewAddressModel: () => AddressModel = () => ({
    id: 0,
    street1: "",
    street2: "",
    city: "",
    state: "",
    postalCode: "",
    countryAlpha3Code: ""
});

export const NewUserAddressModel: () => UserAddressModel = () => ({
    id: 0,
    addressType: AddressType.Default,
    address: NewAddressModel()
});

export const NewUserProfileDataModel: () => UserProfileDataModel = () => ({
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    enabled: true,
    forcePasswordReset: false
});

export const NewUserContactModel: () => UserContactModel = () => ({
    id: 0,
    contactType: ContactType.Undefined,
    contact: "",
    phoneExtension: ""
});

export const NewUserDataModel: () => UserDataModel = () => ({
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    enabled: true,
    forcePasswordReset: false,
    userAddresses: [NewUserAddressModel()],
    contacts: [],
    userClients: []
});

export function MapUserModel(user: UserModel): UserDataModel {
    const result: UserDataModel = {
        userName: user.userName,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        enabled: user.enabled,
        forcePasswordReset: user.forcePasswordReset,
        contacts: user.contacts,
        userAddresses: user.userAddresses,
        userClients: user.userClients
    };

    return result;
}

export function MapCountryModel(country: CountryModel): CacheLookup {
    const result: CacheLookup = {
        id: 0,
        key: country.countryAlpha3Code,
        label: country.name
    };

    return result;
}

export function MapApplicationModel(application: ApplicationModel): CacheLookup {
    const result: CacheLookup = {
        id: application.id,
        key: application.clientId,
        label: application.clientName
    };

    return result;
}
