import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import { useAppDispatch } from '../../app/hooks';
import { TextInput } from '@lp/lp-ui';
import { ApiViewResponseError, NewPasswordRequestModel } from '../../interfaces';
import { changePassword } from '../../app/userSlice';
import PasswordRequirements, {
    IsPasswordValid, PasswordValidationResult, ValidatePassword
} from '../passwordRequirements';
import { UserPanelProps } from '.';

const UserSecurityPanel: React.FC<UserPanelProps> = ({ handleUnauthorized }: UserPanelProps) => {
    const dispatch = useAppDispatch();
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [passwordValidity, setPasswordValidity] = useState<PasswordValidationResult>({
        lengthGt8: false,
        upperCaseChar: false,
        lowerCaseChar: false,
        digit: false,
        specialChar: false,
        matchConfirmPassword: false
    });

    const formik = useFormik<NewPasswordRequestModel>({
        initialValues: { currentPassword: '', password: '', confirmPassword: '' },
        enableReinitialize: true,
        onSubmit: async (values) => {
            if (!formik.dirty) {
                return true;
            }

            const result = await dispatch(changePassword(values));
            const success = result.meta.requestStatus === 'fulfilled';
            if (success) {
                resetForm();
            }
            else {
                const errors = result.payload as ApiViewResponseError[];
                if (errors.some(e => e.status === '401')) {
                    handleUnauthorized();
                }
            }
            return success;
        }
    });

    const {
        errors,
        touched,
        getFieldProps,
        handleChange,
        submitForm
    } = formik;

    const handleCurrentPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(e);
        setCurrentPassword(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(e);
        setPassword(e.target.value);
        const validationResult = ValidatePassword(e.target.value, confirmPassword);
        setPasswordValidity(validationResult);
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(e);
        setConfirmPassword(e.target.value);
        const validationResult = ValidatePassword(password, e.target.value);
        setPasswordValidity(validationResult);
    };

    const resetForm = () => {
        formik.resetForm();
        setCurrentPassword('');
        setPassword('');
        setConfirmPassword('');
        const validationResult = ValidatePassword('', '');
        setPasswordValidity(validationResult);
    }

    return (
        <React.Fragment>
            <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={submitForm} >
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography variant="h5" color="primary">
                                    Password
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <PasswordRequirements valid={passwordValidity} />
                            </Grid>
                            <Grid container spacing={0} columnSpacing={1}>
                                <Grid item xs={7}>
                                    <TextInput
                                        isEditing={true}
                                        type="password"
                                        label="Enter current password"
                                        required
                                        {...getFieldProps("currentPassword")}
                                        onChange={handleCurrentPasswordChange}
                                        error={Boolean(touched.currentPassword && errors.currentPassword)}
                                        helperText={touched.currentPassword && errors.currentPassword}
                                        inputProps={{ autoComplete: 'new-password' }}
                                    />
                                </Grid>
                                <Grid item xs={5}></Grid>
                                <Grid item xs={7}>
                                    <TextInput
                                        isEditing={true}
                                        type="password"
                                        label="Enter new password"
                                        required
                                        {...getFieldProps("password")}
                                        onChange={handlePasswordChange}
                                        error={Boolean(touched.password && errors.password)}
                                        helperText={touched.password && errors.password}
                                        inputProps={{ autoComplete: 'new-password' }}
                                    />
                                </Grid>
                                <Grid item xs={5}></Grid>
                                <Grid item xs={7}>
                                    <TextInput
                                        isEditing={true}
                                        type="password"
                                        label="Confirm new password"
                                        required
                                        {...getFieldProps("confirmPassword")}
                                        onChange={handleConfirmPasswordChange}
                                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                        helperText={touched.confirmPassword && errors.confirmPassword}
                                        inputProps={{ autoComplete: 'new-password' }}
                                    />
                                </Grid>
                                <Grid item xs={5}></Grid>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={2}>
                                    <Button type="button" fullWidth variant="outlined" onClick={resetForm}>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        type="button" fullWidth variant="contained" onClick={submitForm}
                                        disabled={!(IsPasswordValid(password, confirmPassword) && currentPassword.length > 0)}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                                <Grid item xs={5}></Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            </FormikProvider>
        </React.Fragment>
    );
}

export default UserSecurityPanel;
