import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ApiViewEntityResponse, ApiViewResponseError } from '../interfaces';
import { ApplicationModel, CountryModel } from '../interfacesProfile';
import { getBodyErrors } from '../services/apiService';
import { MapApplicationModel, MapCountryModel } from '../functions'
import identityService from '../services/identityService';

export enum CachedEntity {
    Country,
    Application
}

export interface CacheLookup {
    id: number,
    key: string,
    label: string
}

type CacheSlice = {
    cache: {
        [E in CachedEntity]: CacheLookup[]
    },
    pending: {
        [E in CachedEntity]: boolean
    }
}

const initialState: CacheSlice = {
    cache: {
        [CachedEntity.Country]: [],
        [CachedEntity.Application]: []
    },
    pending: {
        [CachedEntity.Country]: false,
        [CachedEntity.Application]: false
    }
}

const CacheSlice = createSlice({
    name: 'cache',
    initialState,
    reducers: {
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCountries.pending, (state) => {
                state.pending[CachedEntity.Country] = true;
            })
            .addCase(fetchCountries.fulfilled, (state, action) => {
                const results = action.payload.data;
                state.cache[CachedEntity.Country] = results.map((country) => MapCountryModel(country));
                state.pending[CachedEntity.Country] = false;
            })
            .addCase(fetchApplications.pending, (state) => {
                state.pending[CachedEntity.Application] = true;
            })
            .addCase(fetchApplications.fulfilled, (state, action) => {
                const results = action.payload.data;
                state.cache[CachedEntity.Application] = results.map((application) => MapApplicationModel(application));
                state.pending[CachedEntity.Application] = false;
            })
    }
});

export const fetchCountries = createAsyncThunk<ApiViewEntityResponse<CountryModel[]>, undefined, { rejectValue: ApiViewResponseError[] }>
    ('cache/fetchCountries', async (_, { rejectWithValue }) => {
        try {
            const response = await identityService.getCountries();
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const fetchApplications = createAsyncThunk<ApiViewEntityResponse<ApplicationModel[]>, undefined, { rejectValue: ApiViewResponseError[] }>
    ('cache/fetchApplications', async (_, { rejectWithValue }) => {
        try {
            const response = await identityService.getApplications();
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export default CacheSlice.reducer;
