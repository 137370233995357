import React, { useEffect } from 'react';
import { useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import {
    Button, Typography, Link, Box, Backdrop, CircularProgress, Divider, Stack
} from '@mui/material';
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { ApiViewResponseError, MfaMethod } from '../../interfaces';
import { createMfaCode, logoutUser, spaResetErrorMessages, spaSessionExpired } from "../../app/loginSlice"
import ErrorsBox from '../errorsBox';
import { parseReturnUrl } from '../../functions';

export default function MfaProvides() {
    const [searchParams] = useSearchParams();
    const loginState = useAppSelector(store => store.login);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (loginState?.userData == null ||
            loginState?.loginData == null) {
            window.location.href = '/' + window.location.search;
        }
        else {
            dispatch(spaResetErrorMessages());
        }
    }, []);

    const handleMfaProviderSelection = async (i: number) => {
        const mfaMethod = loginState.loginData!.mfaMethods[i];
        const dispatchResult = await dispatch(createMfaCode(mfaMethod.method));
        if (dispatchResult.meta.requestStatus == "fulfilled") {
            navigate({
                pathname: '/mfa',
                search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
            });
        }
        else if (dispatchResult.meta.requestStatus == "rejected") {
            const errors = dispatchResult.payload as ApiViewResponseError[];
            if (errors?.some(e => e.code == "UNAUTHORIZED")) {
                dispatch(spaSessionExpired());
                navigate({
                    pathname: '/',
                    search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
                });
            }
        }
    };

    const handleCancel = async () => {
        const dispatchResult = await dispatch(logoutUser());
        if (dispatchResult.meta.requestStatus == "fulfilled") {
            navigate({
                pathname: '/',
                search: `?${createSearchParams({ returnUrl: parseReturnUrl(searchParams.get("returnUrl")) })}`
            });
        }
    };

    return (
        <React.Fragment>
            <Box width={1}>
                <Stack spacing={1.5}>
                    <Typography variant="h4" color="primary">Log in to your account</Typography>
                    <Typography variant="body1">Choose method to receive authentication code</Typography>
                    <ErrorsBox errors={loginState.messages} />
                    {loginState.loginData?.mfaMethods.map((d, i) => (
                        <React.Fragment key={i}>
                            <Box>
                                <Button type="button" id={`${ MfaMethod[d.method].toLowerCase() }-mfaprovider-send-button`} variant="contained" onClick={() => { handleMfaProviderSelection(i); }} fullWidth>
                                    {d.contact}
                                </Button>
                                {i + 1 < (loginState.loginData?.mfaMethods.length ?? 0) &&
                                    <Box mt={1.5} pl={3} pr={3}>
                                        <Divider><Typography variant="body2" color="text.secondary">OR</Typography></Divider>
                                    </Box>
                                }
                            </Box>
                        </React.Fragment>
                    ))}
                    <Typography variant="body1" pt={1}>
                        <Link id="mfaproviders-cancel-link" href="#" onClick={handleCancel}>Cancel</Link>
                    </Typography>
                </Stack>
            </Box>
            <Backdrop open={loginState.loading} className="loading">
                <CircularProgress />
            </Backdrop>
        </React.Fragment>
    );
}
