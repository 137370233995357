import React from 'react';
import { Grid, Typography } from '@mui/material';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import { useAppSelector } from '../../app/hooks';
import useCache from '../../app/useCache';
import { CachedEntity } from '../../app/cacheSlice';

export default function UserApplicationsPanel() {
    const userState = useAppSelector(store => store.user);
    const applications = useCache(CachedEntity.Application);

    return (
        <React.Fragment>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5" color="primary">
                        Applications
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={0} columnSpacing={0}>

                        {applications.map((application) => (
                            <React.Fragment key={application.key}>
                                <Grid item xs={1}>
                                    {userState?.data?.userClients.some(uc => uc.clientId === application.id)
                                        ? <ToggleOnIcon fontSize='large' color='primary' />
                                        : <ToggleOffIcon fontSize='large' color='disabled' />}
                                </Grid>
                                <Grid item xs={11} sx={{ pt: 0.5 }}>
                                    {application.label ? application.label : application.key}
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}
