import {
    createSlice, createAsyncThunk, isRejectedWithValue, isPending, isFulfilled, PayloadAction
} from '@reduxjs/toolkit';
import {
    ApiViewEntityResponse, ApiViewResponseError, ApplicationModel, NewPasswordRequestModel
} from '../interfaces'
import {
    UserDataModel, UserModel, UserContactDataModel,
    UserAddressModel, UserPersonalDetailsModel
} from '../interfacesProfile'
import { MapUserModel } from '../functions';
import identityService from '../services/identityService';
import { getBodyErrors } from '../services/apiService';
import { Message, MessageLevel } from '@lp/lp-ui';

interface UserState {
    loading: boolean,
    loadingAuditLogs: boolean,
    snack: string | null,
    id: number,
    data: UserDataModel | null,
    applications: ApplicationModel[],
    messages: Message[]
}

const initialState: UserState = {
    loading: false,
    loadingAuditLogs: false,
    snack: null,
    id: 0,
    data: null,
    applications: [],
    messages: []
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        idSelected: (state, action: PayloadAction<number>) => {
            state.id = action.payload;
        },
        userDataSelected: (state, action: PayloadAction<UserDataModel>) => {
            state.data = action.payload;
        },
        userDataReset: (state) => {
            state.data = null;
        },
        snackDisplayed: (state, action: PayloadAction<string | null>) => {
            state.snack = action.payload;
        },
        messagesDisplayed: (state, action: PayloadAction<Message[]>) => {
            state.messages = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(getCurrentUserApplications.fulfilled, (state, action) => {
                state.applications = action.payload.data;
            })
            .addMatcher(isApiFulfilledWithoutSnack, (state, action) => {
                state.loading = false;
                state.messages = [];
                state.snack = null;
                state.data = MapUserModel(action.payload.data);
            })
            .addMatcher(isApiFulfilledWithSnackMessage, (state, action) => {
                state.loading = false;
                state.messages = [];
                state.snack = "Record Saved.";
                state.data = MapUserModel(action.payload.data);
            })
            .addMatcher(isApiPending, (state) => {
                state.loading = true;
            })
            .addMatcher(isApiRejected, (state, action) => {
                state.loading = false;
                state.snack = null;
                if (action.payload) {
                    state.messages = action.payload.map(e => ({ details: e.detail, level: MessageLevel.Error }));
                }
            })
    }
});

export const getUser = createAsyncThunk<ApiViewEntityResponse<UserModel>, undefined, { rejectValue: ApiViewResponseError[] }>
    ('user/get', async (_, { rejectWithValue }) => {
        try {
            const response = await identityService.getUser();
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const getCurrentUserApplications = createAsyncThunk<ApiViewEntityResponse<ApplicationModel[]>, undefined, { rejectValue: ApiViewResponseError[] }>
    ('user/applications/get', async (_, { rejectWithValue }) => {
        try {
            const response = await identityService.getCurrentUserApplications();
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const updateUserPersonalDetails = createAsyncThunk<ApiViewEntityResponse<UserModel>, UserPersonalDetailsModel, { rejectValue: ApiViewResponseError[] }>
    ('user/personal-details/update', async (request: UserPersonalDetailsModel, { rejectWithValue }) => {
        try {
            const response = await identityService.updateUserPersonalDetails(request);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const createUserAddress = createAsyncThunk<ApiViewEntityResponse<UserModel>, UserAddressModel, { rejectValue: ApiViewResponseError[] }>
    ('user/address/create', async (request: UserAddressModel, { rejectWithValue }) => {
        try {
            const response = await identityService.createUserAddress(request);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const updateUserAddress = createAsyncThunk<ApiViewEntityResponse<UserModel>, UserAddressRequest, { rejectValue: ApiViewResponseError[] }>
    ('user/address/update', async (request: UserAddressRequest, { rejectWithValue }) => {
        try {
            const response = await identityService.updateUserAddress(request.userAddressId, request.userAddress);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const createUserContact = createAsyncThunk<ApiViewEntityResponse<UserModel>, UserContactDataModel, { rejectValue: ApiViewResponseError[] }>
    ('user/contact/create', async (request: UserContactDataModel, { rejectWithValue }) => {
        try {
            const response = await identityService.createUserContact(request);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const updateUserContact = createAsyncThunk<ApiViewEntityResponse<UserModel>, UserContactRequest, { rejectValue: ApiViewResponseError[] }>
    ('user/contact/update', async (request: UserContactRequest, { rejectWithValue }) => {
        try {
            const response = await identityService.updateUserContact(request.contactId, request.contact);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const deleteUserContact = createAsyncThunk<ApiViewEntityResponse<UserModel>, number, { rejectValue: ApiViewResponseError[] }>
    ('user/contact/delete', async (contactId: number, { rejectWithValue }) => {
        try {
            const response = await identityService.deleteUserContact(contactId);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const updateUserName = createAsyncThunk<ApiViewEntityResponse<UserModel>, string, { rejectValue: ApiViewResponseError[] }>
    ('user/update/userName', async (userName: string, { rejectWithValue }) => {
        try {
            const response = await identityService.updateUserName(userName);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

export const changePassword = createAsyncThunk<ApiViewEntityResponse<UserModel>, NewPasswordRequestModel, { rejectValue: ApiViewResponseError[] }>
    ('user/update/password', async (request: NewPasswordRequestModel, { rejectWithValue }) => {
        try {
            const response = await identityService.changeProfilePassword(request);
            return response;
        } catch (err: any) {
            return rejectWithValue(await getBodyErrors(err));
        }
    });

const isApiPending = isPending(
    getUser, updateUserPersonalDetails, createUserAddress, updateUserAddress,
    createUserContact, updateUserContact, deleteUserContact, updateUserName,
    changePassword);
const isApiRejected = isRejectedWithValue(
    getUser, updateUserPersonalDetails, createUserAddress, updateUserAddress,
    createUserContact, updateUserContact, deleteUserContact, updateUserName,
    changePassword);
const isApiFulfilledWithSnackMessage = isFulfilled(
    updateUserPersonalDetails, createUserAddress,
    updateUserAddress, createUserContact, updateUserContact,
    deleteUserContact, changePassword);
const isApiFulfilledWithoutSnack = isFulfilled(getUser, updateUserName);

export default userSlice.reducer

export const {
    idSelected,
    userDataSelected,
    userDataReset,
    snackDisplayed,
    messagesDisplayed
} = userSlice.actions;

export interface UserAddressRequest {
    userAddressId: number,
    userAddress: UserAddressModel
}

export interface UserContactRequest {
    contactId: number,
    contact: UserContactDataModel
}
